exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressionen-js": () => import("./../../../src/pages/impressionen.js" /* webpackChunkName: "component---src-pages-impressionen-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kartoffelsorten-js": () => import("./../../../src/pages/kartoffelsorten.js" /* webpackChunkName: "component---src-pages-kartoffelsorten-js" */),
  "component---src-pages-kontakt-anfahrt-js": () => import("./../../../src/pages/kontakt-anfahrt.js" /* webpackChunkName: "component---src-pages-kontakt-anfahrt-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

